export default {
    state: () => ({
        listStates : {

        },
        defaultState: {
            itemPagination: {
                start: 0,
                limit: 10
            },
            itemFilters: {

            },
            itemSorting: {
                key: 'id',
                direction: 'desc',
            },
        }
    }),
    mutations: {
        setPagination (state, payload) {
            let listName = payload.listName;


            if ( ! state.listStates.hasOwnProperty(listName)) {
                console.log('setting default');
                state.listStates[listName] = Object.assign(state.defaultState);
            }
            console.log('setting pagination');
            state.listStates[listName].itemPagination = {
                start: payload.start,
                limit: payload.limit
            }

        }
    },
    actions: {  },
    getters: {
        listDefaultState (state) {
            return Object.assign(state.defaultState);
        },
        listStateExists: (state) => (name) => {
            return state.listStates.hasOwnProperty(name);
        },
        listPagination: (state, getters, c, d) => (name) => {
            if ( ! getters['listStateExists'](name)) {
                return  getters['listDefaultState'].itemPagination;
            } else {
                return state.listStates[name].pagination
            }

            return 'test';
        }
    }
};
